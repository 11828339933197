import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { setupAuthorizationHeaderInterceptor } from "../axios";

export class OidcManager implements IOidcManager {
  public userManager: UserManager;
  private store: any;
  private isSignInCallbackProcessed: boolean;

  constructor(store) {
    this.store = store;
    this.isSignInCallbackProcessed = false;

    this.userManager = new UserManager({
      client_id: process.env.REACT_APP_CLIENT_ID,
      response_type: "code",
      scope: process.env.REACT_APP_SCOPE,
      authority: process.env.REACT_APP_AUTHORITY_URL,
      silent_redirect_uri: `${window.location.protocol}${
        window.location.hostname
      }${
        window.location.port ? `:${window.location.port}` : ""
      }/silent_renew.html`,
      redirect_uri: process.env.REACT_APP_PUBLIC_REDIRECT_URL,
      post_logout_redirect_uri: `${window.location.protocol}${
        window.location.hostname
      }${window.location.port ? `:${window.location.port}` : ""}`,
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: true,
      monitorSession: true,
      revokeTokensOnSignout: true,
      metadataUrl: process.env.REACT_APP_PUBLIC_SSO_METADATA_URL,
      extraQueryParams: { audience: "time-api" },
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    this.initializeEventHandlers();
    this.setupAxios();
  }

  initializeEventHandlers() {
    this.userManager.events.addUserLoaded(() => {
      this.store.dispatch({
        type: "SET_AUTH_STATE",
        payload: { type: "authenticated" },
      });
    });

    this.userManager.events.addUserUnloaded(() => {
      this.store.dispatch({
        type: "SET_AUTH_STATE",
        payload: { type: "idle" },
      });
    });

    this.userManager.events.addSilentRenewError(() => {
      this.store.dispatch({
        type: "SET_AUTH_STATE",
        payload: { type: "idle" },
      });
      this.logout();
    });
  }

  setupAxios() {
    setupAuthorizationHeaderInterceptor(this);
  }

  async login() {
    try {
      this.store.dispatch({
        type: "SET_AUTH_STATE",
        payload: { type: "loading" },
      });
      await this.userManager.signinRedirect({
        url_state: window.location.href,
      });
    } catch (err) {
      console.error("Login error:", err);
      this.handleError(err, "Login error");
    }
  }

  async logout() {
    try {
      this.store.dispatch({
        type: "SET_AUTH_STATE",
        payload: { type: "loading" },
      });
      await this.userManager.removeUser();
      await this.userManager.signoutRedirect({
        extraQueryParams: {
          client_id: process.env.REACT_APP_CLIENT_ID,
          logout_uri: process.env.REACT_APP_PUBLIC_REDIRECT_URL,
        },
      });
    } catch (err) {
      console.error("Logout error:", err);
      this.handleError(err, "Logout error");
    }
  }

  onLoad = async () => {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search.substring(1))
    );
    if ("error" in params) {
      this.handleError(new Error(`SSO error: ${params.error}`), "Onload error");
      return;
    }

    if (this.isSignInCallbackProcessed) {
      return;
    }

    if ("code" in params) {
      this.isSignInCallbackProcessed = true;
      try {
        const response = await this.userManager.signinCallback();
        if (response) {
          this.store.dispatch({
            type: "SET_AUTH_STATE",
            payload: { type: "authenticated", user: response },
          });
          if (typeof response.url_state === "string") {
            navigateTo(`${response.url_state}`, true);
          } else {
            navigateTo(window.location.pathname, true);
          }
          return;
        }
      } catch (err) {
        this.handleError(err, "Signin callback error");
      }
    }

    const currentState = this.getState();
    if (currentState.type !== "authenticated") {
      try {
        let existingUser = await this.userManager.getUser();
        if (existingUser) {
          await this.userManager.signinSilent();
          // Get user object after signin with update information
          existingUser = await this.userManager.getUser();
          this.store.dispatch({
            type: "SET_AUTH_STATE",
            payload: {
              type: "authenticated",
              user: existingUser,
            },
          });
        } else {
          await this.login();
        }
      } catch (err) {
        console.warn(err);
        await this.login();
      }
    }
  };

  handleError(error: any, message: any) {
    this.store.dispatch({
      type: "SET_AUTH_STATE",
      payload: { type: "error", error: error.message },
    });
    console.error(`${message}:`, error);
  }

  getUser = async () => {
    const user = await this.userManager.getUser();
    return user;
  };

  getState = () => {
    const state = this.store.getState();
    return state.oidc;
  };
}

interface IOidcManager {
  login: (redirectPath?: string) => Promise<void>;
  logout: () => Promise<void>;
  onLoad: () => Promise<void>;
}

const navigateTo = (url: string, replaceState = false) => {
  if (replaceState) {
    window.history.replaceState(null, "", url);
  } else {
    window.history.pushState(null, "", url);
    window.dispatchEvent(new PopStateEvent("popstate"));
  }
};
