import React, { createContext, useEffect, useMemo } from "react";
import { Provider } from "react-redux";
import store from "../redux/store";
import { OidcManager } from "./userManager.ts";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const authManager = useMemo(() => new OidcManager(store), []);

  useEffect(() => {
    authManager && authManager.onLoad();
  }, [authManager]);

  return (
    <AuthContext.Provider value={authManager}>
      <Provider store={store}>{children}</Provider>
    </AuthContext.Provider>
  );
};
