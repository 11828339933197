import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { getCurrentUser } from "../redux/modules/users/usersSelectors";
import ForbiddenPage from "../components/pages/ForbiddenPage/ForbiddenPage";
import FullScreenLoader from "../components/common/FullScreenLoader/FullScreenLoader";

const withAuthorization = () => (WrappedComponent, redirectUrl) => {
  const WithAuthorization = (props) => {
    const { user, ...other } = props;

    if (!user || user.authorization.expired) {
      return <FullScreenLoader />;
    } else if (user && !user.authorization.expired) {
      return <WrappedComponent {...other} />;
    } else if (redirectUrl) {
      return <Navigate to={redirectUrl} replace />;
    } else {
      return <ForbiddenPage />;
    }
  };

  WithAuthorization.propTypes = {
    user: PropTypes.object,
  };

  const mapStateToProps = (state) => ({
    user: getCurrentUser(state),
  });

  return connect(mapStateToProps)(WithAuthorization);
};

export default withAuthorization;
