import React from "react";
import { AuthProvider } from "../oidc/authProvider";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import AppRoutes from "./routes";
import ModalRoot from "./ModalRoot";

const globalTheme = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
  },
  palette: {
    white: "#ffffff",
    whitegrey: "#f7f7f7",
    grey: {
      light: "#f3f3f3",
      main: "#a9a9a9",
      dark: "#7f7f7f",
    },
    calendar: {
      weekEnd: "#f9f9f9",
      holiday: "#fae8e8",
    },
    cancelButton: {
      main: "#bbb",
      contrastText: "#fff",
    },
    border: {
      main: "#cccccc",
    },
    text: {
      dark: "#000",
      light: "#808080",
    },
    whiteblue: "#f4f8fd",
    lightblue: "#dfedff",
    black: {
      main: "#000000",
    },
    red: {
      main: "#fc5757",
    },
    yellow: {
      main: "#f8e41c",
    },
    secondary: {
      light: "#6b7dea",
      main: "#5064d6",
      dark: "#3c52c9",
      contrastText: "#ffffff",
    },
    primary: {
      light: "#3cafa4",
      main: "#2ba89d",
      dark: "#009688",
      contrastText: "#ffffff",
    },
  },
  border: {
    lightgrey: "1px solid rgba(224, 224, 224, 1)",
  },
});

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={globalTheme}>
        <AuthProvider>
          <>
            <ModalRoot />
            <AppRoutes />
          </>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
