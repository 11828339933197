import axios from "axios";
import moment from "moment";

export const apiClient = axios.create({});

export function setupAuthorizationHeaderInterceptor(oidcManager) {
  apiClient.interceptors.request.use(async (config) => {
    const user = await oidcManager.getUser();
    const utcOffset = moment().utcOffset();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: user ? `Bearer ${user.access_token}` : undefined,
        "Utc-Offset": utcOffset.toString(),
      },
    };
  });
}
