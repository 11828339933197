import React from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import roleConstants from "../../../helper/roleConstants";
import withRoles from "../../../../hocs/withRoles";
import MenuItemLink from "./MenuItemLink";
import CalendarIcon from "../../../../assets/icons-svg/calendar.svg";
import GraphIcon from "../../../../assets/icons-svg/graph-2.svg";
import ManagerIcon from "../../../../assets/icons-svg/manager-2.svg";
import TextDocumentIcon from "../../../../assets/icons-svg/text-document.svg";
import withAuthorization from "../../../../hocs/withAuthorization";

const PREFIX = "NavigationSidebar";

const classes = {
  root: `${PREFIX}-root`,
  logoWrapper: `${PREFIX}-logoWrapper`,
  logo: `${PREFIX}-logo`,
  menuWrapper: `${PREFIX}-menuWrapper`,
  menuItem: `${PREFIX}-menuItem`,
  menuLink: `${PREFIX}-menuLink`,
  menuItemIcon: `${PREFIX}-menuItemIcon`,
  logoutButton: `${PREFIX}-logoutButton`,
  menuItemText: `${PREFIX}-menuItemText`,
};

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSidebarOpen",
})(({ theme, isSidebarOpen }) => ({
  flex: 1,
  outline: "none",
  position: isSidebarOpen && "absolute",
  height: isSidebarOpen && "calc(100% - 58px)",
  top: isSidebarOpen && "58px",
  padding: isSidebarOpen && "0 20px",
  background:
    isSidebarOpen &&
    `linear-gradient(to bottom, ${theme.palette.secondary.dark}, ${theme.palette.secondary.light})`,

  [`& .${classes.logoWrapper}`]: {
    padding: "16px 0 20px",
  },

  [`& .${classes.logo}`]: {
    height: "3rem",
    width: "9rem",
    backgroundImage: `url('/logo.svg')`,
    backgroundSize: "cover",
  },

  [`& .${classes.menuWrapper}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.menuItem}`]: {
    padding: "5px 0",
  },

  [`& .${classes.menuLink}`]: {
    "&:hover": {
      backgroundColor: "none",
    },
    textDecoration: "none",
    display: "flex",
  },

  [`& .${classes.menuItemIcon}`]: {
    marginRight: "10px",
  },

  [`& .${classes.logoutButton}`]: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },

  [`& .${classes.menuItemText}`]: {
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      color: "rgba(255, 255, 255, 1)",
    },
  },
}));

const ManagerMenuItem = withRoles([
  roleConstants.superManager,
  roleConstants.manager,
])(MenuItemLink);
const UserMenuItem = withAuthorization()(MenuItemLink);
const UserEditorMenuItem = withRoles([
  roleConstants.superManager,
  roleConstants.userEditor,
])(MenuItemLink);

const NavigationSidebar = (props) => {
  const { isSidebarOpen, handleBlur } = props;
  return (
    <Root isSidebarOpen={isSidebarOpen} tabIndex="0" onBlur={handleBlur}>
      <div className={classes.logoWrapper}>
        <Link to="/">
          <div className={classes.logo} />
        </Link>
      </div>
      <div className={classes.menuWrapper}>
        <UserMenuItem
          link="/"
          text="Week timesheet"
          Icon={CalendarIcon}
          onItemClick={handleBlur}
        />
        <ManagerMenuItem
          link="/reports/monthly"
          text="Reports"
          Icon={TextDocumentIcon}
          onItemClick={handleBlur}
        />
        <ManagerMenuItem
          link="/activities"
          text="Manage activities"
          Icon={GraphIcon}
          onItemClick={handleBlur}
        />
        <ManagerMenuItem
          link="/users"
          text="Manage users"
          Icon={ManagerIcon}
          onItemClick={handleBlur}
        />
        {/* <ManagerMenuItem
          link="/employees"
          text="Employees"
          onItemClick={handleBlur}
        />
        <UserEditorMenuItem
          link="/subordinates"
          text="Subordinates"
          onItemClick={handleBlur}
        /> */}
      </div>
    </Root>
  );
};

NavigationSidebar.defaultProps = {
  handleBlur: () => {},
};

export default NavigationSidebar;
